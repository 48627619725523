.card-body {
  background-color: var(--bg-color);
}

.overlay-div {
  font-style: italic;
  border-radius: var(--border-radius);
  background-color: red;
  position: absolute;
  top: 0;
  right: 0;
}

.overlay-div:hover {
  color: black !important;
  background-color: white;
  font-size: large;
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .service-card {
    width: 40vw;
  }
}

//create breakpoint for desktop
@media screen and (min-width: 1024px) {
  .overlay-div {
  }

  .profile-card {
    width: 40vw;
  }
}
