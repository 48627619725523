* {
  box-sizing: border-box;
}

@font-face {
  font-family: Nunito;
  src: url(Nunito-Regular.ttf);
}

// body,
// p,
// span,
// div,
// a,
// html,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// button,
// input,
// select,
// textarea,
// b {
//     font-family: Nunito !important;
// }

html {
  --color-theme: #2d3095;
  --bg-color: #2d3095;
  --blue-color: #2d3095;
  --second-theme: #aa2320;
  --bg-color-imge: linear-gradient(to right, #2d3095, #090b47);
  --btn-border: 5px;
  --button-theme: linear-gradient(to right, #2d3095, #090b47);
  --color-line: #2d3095;
  --color-text: #fff;
  --image-border-radius: 10px;
  --border-radius: 8px;
  scroll-behavior: smooth;

  @media only screen and (max-width: 768px) {
    --border-radius: 4px;
  }
}
.second-theme {
  color: #aa2320 !important;
}
.bg-second-theme {
  background-color: #aa2320 !important;
}

.w-90{
  width: 90% !important;
}

.top-50 {
  top: 50%;
}

.bg-theme {
  background-image: var(--button-theme);
}

.underline {
  display: block;
  border: 1px solid var(--color-line);
  width: 90%;
  margin: 10px auto;
  height: 1px;
  animation: underline 1s linear;
}

@keyframes underline {
  from {
    width: 0%;
  }

  to {
    width: 90%;
  }
}

.border-radius {
  border-radius: var(--border-radius);
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-7 {
  opacity: 0.7;
}

.top-40 {
  top: 40%;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 1.9em;
}

h4 {
  font-size: 1.3em;
}

.read-more {
  color: var(--color-line);
  text-decoration: underline;
}

.line-slide {
  background-color: #ccc;
}

.hover:hover {
  opacity: 0.5;
}

.hover-zoom:hover {
  transform: scale(1.05);
}

.z-index-1 {
  z-index: 1;
}

// apply this style in small phones

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.2em;
  }

  h4 {
    font-size: 1em;
  }

  .p-hide {
    display: none !important;
  }
}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.4em;
  }

  h3 {
    font-size: 1.7em;
  }

  h4 {
    font-size: 1.3em;
  }

  .d-hide {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .d-hide {
    display: none !important;
  }
}
