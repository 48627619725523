.link-container {
  position: relative;
  top: 0px;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 6px;
  // background-image: var(--bg-color-imge);
}
.dropdown {
  display: none;
  position: absolute;
  bottom: -5.2em;
  left: 0px;
  width: 15em;
  z-index: 0;
  border-radius: 0px 0px 8px 8px;
  a {
    color: #aa2320 !important;
    text-decoration: none;
  }
  div:hover {
    background-color: #d6d4d4;
  }
}
.link-container:hover {
  .dropdown {
    display: block;
  }
}
.active {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.link-name {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 1;

  a {
    color: white;
    font-size: 20px;
    border-radius: 6px;
    text-decoration: none;
  }
}
.link-container:hover {
  .link-name {
    background-color: transparent;
    a {
      color: var(--second-theme);
    }
  }
  .bg-effect-hover {
    width: 100%;
  }
}
.bg-effect-hover {
  width: 0%;
  height: 100%;
  border-radius: 6px;
  transition: width 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: white;
}

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  a {
    font-size: 15px;
  }
}

/* For tablets phones: */

@media only screen and (max-width: 1024px) {
  a {
    font-size: 17px;
  }
}
