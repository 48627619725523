.services-card-body:hover{
top:-5%;
transform: scale(1.1);
transition: transform 1s;
}
.services-card-body{
    height: auto;
    width:30%;
    margin-left: 3%;
    transform: scale(1);
    position: relative;

    .caption {
        text-align: center;
        background-image: var(--bg-color-imge);
        color: white;
        border-radius: 5px;
        transition: background-image 2s;
    }
    .caption:hover{
        background-image: var(--button-theme);
        color: var(--color-line);
    }

    .contents {
      
        height: auto;
        background-color: lightgray;
       
        img{
            width: 80%;
            height: auto;
            margin-top: 10%;
            z-index: 0;
        
           
        }


        .services-card-footer{
            height: auto;
            background-color: rgba(0, 0, 0,0.6);
            transition: background-color 1s;
            position: "absolute";
            bottom: "0%" ;
            width:100%;
            z-index: 10;
            p{
                color:white;
                font-size:17px;
                font-weight: bold;
            }
        }
        .services-card-footer:hover{
            background-color: rgba(0, 0, 0,0.8);
        }

       
    }
}

@media only screen and (max-width: 768px) {
    .services-card-body{
        width: 95%;
        margin-left: 2.5%;
        height:auto;

    }
}
