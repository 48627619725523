#services {
  .slick-dots li button:before {
    font-size: 22px;
  }

  .slick-dots {
    bottom: -37px;
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 40px;
  }

  .slick-prev {
    left: -44px;
  }

  .swipe {
    color: var(--color-line);
    opacity: 0;
  }

  .delay-1-5 {
    animation: swipe 2s linear 1.5s infinite normal none running;
  }

  @keyframes swipe {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .slick-prev,
  .slick-next {
    display: none;
    z-index: -1;
  }

  .slick-prev {
    left: 0px !important;
  }
  .slick-next {
    right: 22px !important;
  }
}

@media only screen and (min-width: 1100px) {
  #services {
    .services-container {
      position: relative;
      width: 90%;
      left: 5%;
    }

    .swipe {
      display: none;
    }
  }

  .slick-prev {
    left: 0px !important;
  }
  .slick-next {
    right: 22px !important;
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
  }
}
