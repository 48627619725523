.im {
  align-self: left;
  float: left;
}
.title {
  color: var(--color-text);
}

.none {
  background-color: rgb(0, 0, 77);
}
@media only screen and (max-width: 768px) {
}
.nav {
  position: absolute;
  top: 0px;
  right: 0px;
  transition: background-color 2s;
  z-index: 100;

  .nav-bar-items {
    transition: background-color 2s;
    z-index: 10;
  }
  .in-viewport {
    position: absolute;
    box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
    background-image: var(--bg-color-imge);
  }

  /* For mobile phones: */

  @media only screen and (max-width: 768px) {
    .nav-bar-items {
      display: none !important;
    }
    .out-viewport {
      .h1 {
        font-size: 18px !important;
      }
    }
  }

  /* For tablets phones: */

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .nav-bar-items {
      display: none !important;
    }
    .out-viewport {
      .h1 {
        font-size: 35px !important;
      }
    }
  }
}
.in-viewport {
  position: absolute;
  background-color: rgb(0, 0, 77);
  box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
  background-image: var(--bg-color-imge);
}

.out-viewport {
  position: fixed;
  background-color: rgb(0, 0, 77);
  box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.25);
  background-image: var(--bg-color-imge);
}
.navbar-d {
  background-color: rgb(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  .nav-bar-items {
    display: none !important;
  }
  .out-viewport {
    .h1 {
      font-size: 18px !important;
    }
  }
}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .nav-bar-items {
    display: none !important;
  }
  .out-viewport {
    .h1 {
      font-size: 35px !important;
    }
  }
}

//This is for desktops

@media only screen and (min-width: 1024px) {
}
