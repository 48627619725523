.service-item-description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  background-color: rgba(0, 0, 0, .4);

}

span {
  display: inline-block;
}

.slick-slide img {
  display: inline-block;
}

.service-item-title {
  background-color: var(--color-theme);
  background-image: var(--bg-color-imge);
  color: #fff;
  padding: 10px;
  border: 1px dotted white;
  box-shadow: 2px 3px 8px black;
}

.service-item-image {
  background-size: cover;
  height: 300px;
}

/* width */
.service-overlay::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.service-overlay::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.service-overlay::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.service-overlay::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.service-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  overflow-y: auto;
  overflow-x: hidden;

  li {
    font-size: 18px;
    margin-bottom: 5px;
  }
}


.service-overlay::-webkit-scrollbar-track {
  background-color: #fff;
}

.service-overlay::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

.service-overlay::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #D9D9D9;

  height: 5px;
}