#footer {
  .service-drop {
    display: none;
  }

  background-color: var(--bg-color);

  .service-parent:hover {
    .service-drop {
      display: block;
    }
  }

  ul li {
    margin: 0 10px;
  }

  ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    transition: all 0.2s;
  }

  ul li a i {
    color: #000000;
    background-color: #fff;
    transition: all 0.2s;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }

  .footer-navbar li:hover a,
  ul li:hover a i {
    color: var(--color-line);
  }

  .footer-end {
    z-index: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .container {
    z-index: 5;
  }

  .subscribe > div:first-of-type {
    padding: 5px 10px;
    background-color: #d9d9d9;
    color: #48494a;
    font-size: 20px;
    height: 40px;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
  }

  .subscribe > div:nth-child(2) input {
    padding: 5px 10px;
    font-size: 18px;
    color: #48494a;
    background-color: #d9d9d9;
    border: none;
    height: 40px;
    outline: none;
  }

  .subscribe > div:nth-child(3) a {
    display: inline-block;
    padding: 5px 10px;
    font-size: 18px;
    color: #f5f5f5;
    background-color: #000000;
    text-decoration: none;
    height: 41px;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    transition: all 0.2s;
  }

  .subscribe > div:nth-child(3) a:hover {
    color: var(--color-line);
  }

  .copyright {
    color: #f5f5f5;
  }
}

@media screen and (max-width: 770px) {
  #footer {
    .footer-end {
      display: none;
    }

    .subscribe > div:first-of-type {
      padding: 6px 6px;
      background-color: #d9d9d9;
      color: #48494a;
      font-size: 17px;
      height: 40px;
      border-bottom-left-radius: 7px;
      border-top-left-radius: 7px;
    }

    .subscribe > div:nth-child(2) input {
      padding: 3px 7px;
      font-size: 14px;
      color: #48494a;
      background-color: #d9d9d9;
      border: none;
      height: 40px;
      outline: none;
    }

    .subscribe > div:nth-child(3) a {
      display: inline-block;
      padding: 6px 6px;
      font-size: 17px;
      color: #f5f5f5;
      background-color: #000000;
      text-decoration: none;
      height: 40px;
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
      transition: all 0.2s;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .footer-contact {
    display: flex;
  }
}
