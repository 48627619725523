.captions {
  border-radius: 10px;
  border: 0.5px solid #ccc;
  text-align: left;

  a {
    text-decoration: none;
  }

  .text {
    color: rgb(92, 92, 92);

    .p2 {
      color: rgb(92, 92, 92);
    }

    .conect-button {
      border: 1px solid white;
      color: rgb(100, 100, 255);
      padding-left: 50px;
      right: 2%;
      bottom: 2%;
      position: absolute;
    }
  }
}

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  .captions {
    padding: 1rem;
    font-size: 14px;
  }
}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .captions {
    padding: 1rem;
    font-size: 15px;
  }
}

//This is for desktops

@media only screen and (min-width: 1024px) {
  .captions {
    padding: 2rem;
    font-size: 16px;
  }
}
