.im {
  align-self: left;
  float: left;
}

.control-icons {
  position: absolute;
  top: 30%;

  background-color: rgba($color: #000, $alpha: 0.5);
  z-index: 10;

  i {
    font-size: 25px;
  }
}
.dots {
  position: absolute;
  bottom: 20%;

  background-color: rgba($color: #000, $alpha: 0.5);
  z-index: 10;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 9px;

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba($color: #fff, $alpha: 0.25);
    transition: all 0.5s;
  }
  .active {
    background-color: rgba($color: #fff, $alpha: 0.8);
  }

  .dot:hover {
    background-color: rgba($color: #fff, $alpha: 0.8);
  }
}

.next-button {
  right: 0;
  border-radius: 13px 0px 0px 13px;
}

.prev-button {
  left: 0;
  border-radius: 0px 13px 13px 0px;
}

.title {
  color: var(--color-text);
}

.header-container {
  position: relative;
  height: 100vh;
}

.header-contacts {
  position: absolute;
  top: 0px;
  left: -2%;

  ul {
    li {
      list-style: none;
    }
  }
}

.button-slider {
  position: absolute;
  bottom: 5%;
  transform: translate(-50%, -50%);
  left: 50%;
  transition: all 1s;

  width: auto;
  animation-delay: 1s;
  z-index: 10;
  a {
    text-decoration: none !important;
  }
}

.banner-header {
  width: 120%;
  height: 50%;
  border-radius: 50%;
  position: relative;
  background-image: var(--bg-color-imge);
  top: -25%;
  left: -12%;
  z-index: 10;
}

.banner-container {
  overflow-x: hidden;
  width: 100vw;
  position: absolute;
  top: -5%;
  z-index: 10;
}

@media only screen and (max-width: 768px) {
  .banner-header {
    top: -25%;
  }

  .header-contacts {
    display: none;
  }

  .button-slider {
    bottom: 10% !important;
  }
}

.nav {
  /* For mobile phones: */

  @media only screen and (max-width: 768px) {
    .control-icons {
      i {
        font-size: 20px !important;
      }
    }

    .nav-bar-items {
      display: none !important;
    }
  }

  /* For tablets phones: */

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .nav-bar-items {
      display: none !important;
    }
  }
}

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  .nav-bar-items {
    display: none !important;
  }
}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .nav-bar-items {
    display: none !important;
  }

  .header-contacts {
    display: none;
  }
}

//This is for desktops

@media only screen and (min-width: 1024px) {
  .button-slider {
    a {
      font-size: 25px;
    }
  }

  .button-slider {
    bottom: 1% !important;
  }

  .button-slider:hover {
    box-shadow: 0px 0px 20px 10px rgba($color: #000, $alpha: 0.25);
    bottom: 3% !important;
  }

  .dots {
    position: absolute;
    bottom: 10%;
  }
}
