.rent-btn:hover{
    background-color: var(--yellow-color) !important;
    color: var(--color-theme) !important;
    transform: scale(1.1);
    transition: .4s;
}

.service-card:hover{

    //create box shadow
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.7);
   transform: scale(1.05);
    background-color: black;
    transition: .4s;
}


//create breakpoint for mobile
@media only screen and (max-width: 600px) {
    .service-component-wrapper {
        flex-direction: column;
    }
}
//create breakpoint for tablet
@media only screen and (min-width: 600px) and (max-width: 1024px) {
    .service-component-wrapper {
        flex-direction: row;
        justify-content: space-around;
    }
}

//create breakpoint for desktop
@media only screen and (min-width: 1024px) {
    .service-component-wrapper {
        flex-direction: row;
        justify-content: space-around;
    }
}
