.whatsappIcon{
    bottom: 3%;
    left:1%;
    z-index:10;
    //box-shadow: 1px 1px 10px 10px green;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color:rgb(0, 128, 0);
    justify-content: center;
    padding: auto;
    margin: auto;
    animation: glow-whatsappIcon 2s linear infinite;
    
    @keyframes glow-whatsappIcon{
        from{
            box-shadow: 0px 0px 0px 0px  rgb(0, 128, 0);
        }
        to{
            box-shadow: 1px 1px 10px 4px  rgb(15, 179, 15);
        }
    }
    a{  width: 50px;
        height: 50px;
       margin: auto;
       padding: auto;
        font-size:2em;
        i{
            color:white;
            transform: translate(-50%, -50%);
            position: relative;
            left: 50%;
            top:30%;
        }
    }

}
