#home {
  background-image: var(--bg-color-imge);
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  position: absolute;
  top: 0px;
}
.glow {
  width: 100px;
  height: 100px;
  background-color: #2d3095; /* Change this to the color you want */
  border-radius: 50%; /* Makes it a circle */
  animation: glow 2s infinite alternate;
  i {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    font-size: 30px;
  }
}
.truck-fast {
  position: absolute;
  bottom: 5%;
  left: 10%;
  z-index: 1;
  font-size: 60px;
  color: white;
  opacity: 0.2;
  animation: truck 20s infinite linear;
}

@keyframes truck {
  0% {
    left: -5%;
    bottom: 0%;
  }
  100% {
    left: 105%;
    bottom: 40%;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 204, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 204, 0, 1), 0 0 30px rgba(255, 204, 0, 0.7);
  }
}
.light-left {
  position: absolute;
  bottom: 7%;
  left: 2%;
  z-index: 10;
}

.light-right {
  position: absolute;
  bottom: 7%;
  right: 2%;
  z-index: 10;
}

.container-slider {
  height: 100vh;
  color: black;
  position: absolute;
  z-index: 1;
  top: 0px;
  background-color: white;
  left: 0px;
  justify-content: center;

  .caption-p {
    display: none;
  }

  .blue-bg {
    height: 0%;
    width: 20%;
    z-index: 100;
    background-color: var(--color-theme);
    position: absolute;
    top: 0px;
    left: 0px;

    animation-delay: 0s;
  }

  .slide-down {
    animation: slide-down 0.7s linear;
  }

  @keyframes slide-down {
    0% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    55% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
  }

  .bg-caption {
    width: 0%;
    z-index: 100;
    background-color: var(--color-line);
    position: absolute;
    height: 10em;
    top: 0px;
    left: 0px;
    animation: close-slide-double 0.5s linear;
    animation-delay: 1.2s;
  }

  @keyframes close-slide-double {
    0% {
      width: 0%;
    }

    50% {
      width: 100%;
      z-index: 0;
    }

    100% {
      width: 0%;
      z-index: 0;
    }
  }

  .bg-caption-reverse {
    width: 100%;
    z-index: 100;
    background-color: var(--color-line);

    position: absolute;
    height: 100%;
    top: 0px;
    left: 0px;
    animation: close-slide-reverse 0.1s linear;
    animation-delay: 1.6s;
  }

  @keyframes close-slide-reverse {
    from {
      width: 100%;
    }

    to {
      width: 0%;
      z-index: 0;
    }
  }

  .title-slider {
    animation-delay: 1s;
    background-color: white;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0px 0px 30px 30px;

    h1 {
      color: #aa2320;
    }
  }

  .orange-bg {
    width: 0%;
    z-index: 100;
    background-color: white;
    animation: close-slide 0.7s linear;
    position: absolute;
    height: 100vh;
  }

  @keyframes close-slide {
    0% {
      width: 0%;
    }

    95% {
      width: 100%;
    }

    100% {
      width: 100%;
    }
  }

  .orange-bg-reverse {
    width: 0%;
    z-index: 100;
    background-color: var(--color-line);
    position: absolute;
    animation-delay: 0.7s;
    left: 0px;
    top: 0;
    height: 100vh;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .close-slide-forward {
    animation-name: close-slide-forward;
  }

  @keyframes close-slide-forward {
    0% {
      width: 100%;
    }

    100% {
      width: 0%;
    }
  }

  .img-logo {
    position: absolute;
    z-index: 100;
    opacity: 1;
    top: 1%;
    left: 0%;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    // transform: translate(-50%, -50%);
    animation-delay: 0s;

    img {
      width: 8em;
      height: 8em;
      margin: auto;
      transition: all 0.5s;
      height: auto;

      background-color: rgba($color: #fff, $alpha: 1);

      border-radius: 0px 0px 50px 50px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    }

    .log-bg-layer {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      z-index: 100;
      background-color: var(--color-theme);
      opacity: 0;
    }
  }
  .img-logo img:hover {
    transform: scale(1.1);
  }
  .caption {
    color: black;
  }

  .img-home {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;

    z-index: 10; //most impoprtant part, this set the priority
    top: 0;

    img {
      width: 100%;
      height: auto;
      transform: scale(1);
      opacity: 1;
      transition: all 2s;
    }
  }

  .img-home:hover {
    img {
      transform: scale(1.5);
    }
  }

  @keyframes zoom-pic {
    from {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
      width: 120%;
    }

    to {
      width: 120%;
      -ms-transform: scale(1.5);
      /* IE 9 */
      -webkit-transform: scale(1.5);
      /* Safari 3-8 */
      transform: scale(1.5);
    }
  }

  .description {
    position: relative;
    top: 5%;
    width: 80%;
    margin: 1px auto;
    animation-delay: 1s;
    p {
      text-transform: uppercase;
      font-weight: 900;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }

  .thing {
    height: 100%;
    width: 100%;
  }

  .but {
    position: relative;
  }

  .line {
    width: 60%;
    height: 20px;
    background-color: #aa2320;
    animation: close-line 1s linear;
    position: absolute;
    top: -50%;
    left: 20%;
    border-radius: 20px;
    z-index: 10;
  }

  @keyframes close-line {
    from {
      width: 0%;
    }

    to {
      width: 70%;
      z-index: 0;
    }
  }

  .slider-body {
    position: relative;
    z-index: 10;

    height: 50%;
    background-color: rgba(45, 48, 149, 0);
    //color: rgb(45, 48, 149);
    top: 50%;
    left: 0px;
    //background-image: linear-gradient(to bottom, #2d3095, #090b47);
  }

  .bg-slider {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: #2d3095;
    opacity: 1;
    z-index: 1;
    background-image: linear-gradient(
      to bottom,
      transparent,
      #2d3095,
      transparent,
      #090b47
    );
  }
}

// apply this style in small phones

/* For mobile phones: */

@media only screen and (max-width: 768px) {
  .glow {
    width: 50px;
    height: 50px;

    i {
      font-size: 20px !important;
    }
  }
  .container-slider {
    .img-logo {
      img {
        height: 8em;
      }
    }

    .img-home {
      width: 100%;
      height: 40%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .slider-body {
      height: 60%;
      top: 40%;
      border-top: 10px solid var(--second-theme);
      left: 0px;
      background-image: linear-gradient(to bottom, #2d3095, #090b47);
    }
  }

  .description {
    p {
      font-size: 1.6em;
    }
  }

  .title-slider {
    h1 {
      font-size: 32px;
    }
  }
}

/* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container-slider {
    .img-logo {
      width: 8em;
      height: 8em;
    }
  }

  .description {
    p {
      font-size: 1.5em;
    }
  }
}

//This is for desktops

@media only screen and (min-width: 1024px) {
  .img-logo {
    img {
      width: 14em !important;
      height: 14em !important;
    }
  }

  .img-home {
    position: absolute !important;
    border-radius: 0px 0px 0px 0px !important;
    top: 0% !important;
    left: 0%;
    height: 60vh !important;
    width: 100%;
    border-top: 5px solid #aa2320;
    border-bottom: 10px solid #aa2320;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0px 0px 200px 200px !important;
    img {
      width: 100% !important;
      height: auto;
    }
  }

  .description {
    position: absolute !important;
    top: 12vh !important;
    left: 50% !important;
    transform: translate(-50%, -20%);
    //background-color: rgba(0, 0, 0, 0.5);
    //border-radius: 30px;
    padding: 2% 2%;
    transition: all 0.5s;
    box-shadow: 0px 0px 16px 4px rgba($color: #000000, $alpha: 0.3);
    border-radius: 0px 0px 100px 100px;
    p {
      font-size: 1.8em;
    }
  }

  .description:hover {
    box-shadow: 0px 0px 16px 4px rgba($color: #000000, $alpha: 0.3);
    border-radius: 0px 0px 100px 100px;
    // top: -65% !important;
    p {
      // color: #aa2320;
    }
  }

  .title-slider {
    // width: 50% !important;
    // background-image: var(--bg-color-imge);
    transition: all 0.5s;
    h1 {
      color: #fff !important;
    }
  }

  .title-slider:hover {
    // width: 50% !important;
    // background-image: linear-gradient(to right, #090b47, #2d3095);
    // box-shadow: 10px 10px 10px rgba($color: #000000, $alpha: 0.25);
    h1 {
      color: #fff !important;
    }
  }
}
