.our-client{ 
  
  .line{
    border:1px solid #ccc;
    width:80%;
    margin:5px auto;
  }
  .our-client-container{
    flex-direction: row;
  }
  .our-client-slide{
    overflow: hidden;
    width:60%;
    background-color: #ffff;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 2px;
    display: flex;
   width: 100%;
   position: relative;
   animation: auto-scroll 15s linear 1s infinite;
    flex-direction:row;
   
   
  }
  @keyframes auto-scroll {
    0% {
      left: 100%;
    }    
    100% {
      left:-100%;
    }
    
  }
    
    li {
      width:35%;
      border:1px solid #ccc;
      padding: auto;
      img{
        width:40%;
         height:auto;
         opacity: 1;
         margin :5px auto;
      }
    }
  
    .bob {
      font-size: 30px;
      display: flex;
      flex-direction: row;
      width:40%;
      text-align: center;
      border:0px solid #ccc;
      padding: auto 1px;
    
      
    i{
      font-size: 50px;
    }
    }
  
   
    li a {
      display: block;
      color: white;
      text-align: center;
      text-decoration: none;
    }
    
}


  /* For mobile phones: */

@media only screen and (max-width: 768px) {

  .our-client{ 
  
    li {
      width:100%;
    }
    .our-client-slide{
      overflow: hidden;
      width:100%;
  }

  
  .bob {
    font-size: 16px;
    width:100%;
    p{
   
      font-weight: bold;
      font-size: 25px;
      i{
        font-size: 35px;
      }
    }
  }
  
  .our-client-container{
    flex-direction: column;
  }
  }
    
}


  /* For tablets phones: */

@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .our-client{ 

    .bob {
      font-size: 20px;
      p{
      
        font-size: 25px;
        font-weight: bold;
       
      }
  
    }
  }

  }

  
//This is for desktops

@media only screen and (min-width: 1024px) {
  .our-client{ 

    .bob {

      p{
        font-size: 1.2em;
        font-weight: bold;
      }
  
    }
  }
   
}